import JSONServer from 'ra-data-json-server';

import handleRequest from 'helpers/handleRequest';

const dataProvider = JSONServer(process.env.REACT_APP_API_URL.replace(/\/$/, ''));

export default ({
  ...dataProvider,
  create: (resource, params) => {
    const changedParams = { ...params };

    return handleRequest(dataProvider.create, resource, changedParams);
  },
  update: (resource, params) => handleRequest(dataProvider.update, resource, params),
});
