import {
  Admin,
  Resource,
} from 'react-admin';
import React from 'react';
import { CssBaseline } from '@material-ui/core';

import i18nProvider from 'utils/i18nProvider';
import dataProvider from 'utils/dataProvider';
import authProvider from 'utils/authProvider';
import routes from './routes';

function App () {
  return (
    <>
      <CssBaseline />
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
      >
        {
          routes.map((route) => (
            <Resource
              create={route.createComponent}
              edit={route.editComponent}
              key={route.name}
              list={route.listComponent}
              name={route.name}
              options={{
                label: route.label,
              }}
            />
          ))
        }
      </Admin>
    </>
  );
}

export default App;
