import {
  Create,
  SimpleForm,
} from 'react-admin';
import React from 'react';

import Form from 'components/Form';

const StockCreate = (props) => (
  <Create
    {...props}
    title="Создать"
  >
    <SimpleForm>
      <Form />
    </SimpleForm>
  </Create>
);

export default StockCreate;
