import Hashes from 'jshashes';

const KEY = 'user';
const HASH = '7e968078fdfd7300aff61e4ff20a49fa7fe041d4910acb086cde5f3992587015';

const SHA256 = new Hashes.SHA256();

const AuthProvider = {
    login: ({ username, password }) => {
        const hash = SHA256.hex(username + password);

        if (hash === HASH) {
            return Promise.resolve(localStorage.setItem(KEY, hash));
        } else {
            return Promise.reject();
        }
    },
    logout: () => (
        Promise.resolve(localStorage.removeItem(KEY))
    ),
    checkError: () => Promise.resolve(),
    checkAuth: () => (
        localStorage.getItem(KEY) === HASH ? Promise.resolve() : Promise.reject()
    ),
    getPermissions: () => Promise.resolve(),
};

export default AuthProvider;
