import React from 'react';
import {Create, required, SimpleForm, TextInput, SaveButton, Toolbar} from "react-admin";
import BranchField from "../BranchField";
import StockField from "../StockField";

const SendNotification = (props) => {
  return (
    <Create
      {...props}
      successMessage="Оповещения отправлены успешно"
      resource='notifications/everybody'
      title="Отправить оповещение"
    >
      <SimpleForm redirect={false} toolbar={
        <Toolbar>
          <SaveButton
            label="Отправить"
            redirect={false}
            submitOnEnter={true}
          />
        </Toolbar>
      }>
        <TextInput
          label="Заголовок"
          source="title"
          validate={required()}
          fullWidth
        />
        <TextInput
          label="Сообщение"
          rows={3}
          rowsMax={3}
          source="message"
          validate={required()}
          fullWidth
          multiline
        />
        <BranchField />
        <StockField />
      </SimpleForm>
    </Create>);
};

export default SendNotification;
