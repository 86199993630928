import {
  List,
  Datagrid,
  TextField,
  // DateField,
  BooleanField,
} from 'react-admin';
import React from 'react';

const StocksList = (props) => (
  <List
    {...props}
    exporter={false}
    title="Акции"
  >
    <Datagrid rowClick="edit">
      <TextField
        label="Название акции"
        source="title"
      />
      {/*<DateField*/}
      {/*  label="Начало акции"*/}
      {/*  source="startsAt"*/}
      {/*/>*/}
      {/*<DateField*/}
      {/*  label="Конец акции"*/}
      {/*  source="expiresAt"*/}
      {/*/>*/}
      <BooleanField
        label="Вся сеть"
        source="allBranches"
      />
    </Datagrid>
  </List>
);

export default StocksList;
