import {
  useQueryWithStore,
  Error,
  BooleanInput,
  FormDataConsumer,
  SelectArrayInput,
  required,
} from 'react-admin';
import React from 'react';
import { Grid } from '@material-ui/core';

const BranchField = (props) => {
  const { data, error } = useQueryWithStore({
    type: 'getMany',
    resource: 'yclients/companies',
    payload: { ids: [] },
  });

  const mappedData = data && data.length ? data.map(d => ({
    ...d,
    id: d.id.toString(),
  })) : [];

  if (error) {
    return <Error />;
  }

  return (
    <Grid
      alignItems="center"
      spacing={3}
      container
    >
      <Grid item>
        <BooleanInput
          label="Все филиалы"
          source="allBranches"
        />
      </Grid>
      <Grid
        item
        xs
      >
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => !formData.allBranches && (
              <SelectArrayInput
                {...rest}
                choices={mappedData}
                label="Филиал"
                optionText="title"
                optionValue="id"
                source="branches"
                style={{ width: '100%' }}
                validate={required()}
              />
            )
          }
        </FormDataConsumer>
      </Grid>
    </Grid>
  );
};

export default BranchField;
