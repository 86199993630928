export default (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  if (file.rawFile) {
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  } else {
    resolve(file);
  }
});
