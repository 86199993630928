import {
  TextInput,
  ImageInput,
  ImageField,
  // DateTimeInput,
  required,
} from 'react-admin';
import React from 'react';
import urlParser from 'url';

import handleFormat from 'helpers/handleFormat';
import TypeField from 'components/TypeField';
import BranchField from "../BranchField";

const Form = () => {
  const validateYoutubeUrl = (url) => {
    if (!url) return undefined;

    const parsedUrl = urlParser.parse(url, true);
    if (parsedUrl && parsedUrl.query && parsedUrl.query.v) return undefined;

    return 'Неверная ссылка';
  }

  return (
    <>
      <TextInput
        label="Название акции"
        source="title"
        validate={required()}
        fullWidth
      />
      <TextInput
        label="Описание акции"
        rows={3}
        rowsMax={3}
        source="description"
        validate={required()}
        fullWidth
        multiline
      />
      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Превью акции"
        placeholder={(<p>Добавить превью</p>)}
        source="previewImage"
        validate={required()}
      >
        <ImageField source="url" />
      </ImageInput>
      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Основное фото"
        placeholder={(<p>Добавить основное фото</p>)}
        source="image"
        validate={required()}
      >
        <ImageField source="url" />
      </ImageInput>
      <TextInput
        label="Ссылка на видео (youtube)"
        source="videoUrl"
        fullWidth
        multiline
        validate={[validateYoutubeUrl]}
      />
      <BranchField />
      {/* <div> */}
      {/*  <DateTimeInput */}
      {/*    label="Начало акции" */}
      {/*    source="startsAt" */}
      {/*    validate={required()} */}
      {/*  /> */}
      {/* </div> */}
      {/* <div> */}
      {/*  <DateTimeInput */}
      {/*    label="Конец акции" */}
      {/*    source="expiresAt" */}
      {/*    validate={required()} */}
      {/*  /> */}
      {/* </div> */}
      <TypeField />
    </>
  );
}

export default Form;
