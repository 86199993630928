import handleBase64 from 'helpers/handleBase64';

export default async (request, resource, params) => {
  const { data } = params;
  const { image, previewImage } = data;

  try {
    const imageBase64 = image ? await handleBase64(image) : undefined;
    const previewImageBase64 = previewImage ? await handleBase64(previewImage) : undefined;

    return request(resource, {
      ...params,
      data: {
        ...params.data,
        ...(image ? { image: imageBase64 } : {}),
        ...(previewImage ? { previewImage: previewImageBase64 } : {}),
      },
    });
  } catch (error) {
    throw new Error(error);
  }
};
