import {
  SelectInput,
  required,
  FormDataConsumer,
  TextInput,
} from 'react-admin';
import React from 'react';
import { Grid } from '@material-ui/core';

import {
  CHOICES,
  OPEN_URL,
} from './constants';

const TypeField = (props) => (
  <Grid
    spacing={3}
    container
  >
    <Grid item>
      <SelectInput
        {...props}
        choices={CHOICES}
        label="Тип акции"
        source="type"
        validate={required()}
      />
    </Grid>
    <Grid
      item
      xs
    >
      <FormDataConsumer>
        {
          ({ formData, ...rest }) => formData.type === OPEN_URL && (
            <TextInput
              {...rest}
              label="Ссылка"
              source="url"
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
  </Grid>
);

export default TypeField;
