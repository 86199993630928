import {
  Edit,
  SimpleForm,
} from 'react-admin';
import React from 'react';

import Form from 'components/Form';

const StockEdit = (props) => (
  <Edit
    {...props}
    title="Изменить"
  >
    <SimpleForm>
      <Form />
    </SimpleForm>
  </Edit>
);

export default StockEdit;
