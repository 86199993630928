import {
  StockCreate,
  StockEdit,
  StocksList,
  SendNotification,
} from './components';

export default ([{
  createComponent: StockCreate,
  editComponent: StockEdit,
  listComponent: StocksList,
  name: 'promotions',
  label: 'Акции',
}, {
  listComponent: SendNotification,
  name: 'notifications',
  label: 'Оповещения',
}]);
